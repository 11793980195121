// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: 'AIzaSyBAIjfKQowcf_jVUkG03NVARLn0E6nxWHY',
    authDomain: 'go-fund-giraffe.firebaseapp.com',
    projectId: 'go-fund-giraffe',
    storageBucket: 'go-fund-giraffe.appspot.com',
    messagingSenderId: '19308974020',
    appId: '1:19308974020:web:c28225f4a1a10821c85e91',
    measurementId: 'G-PRS9462JG8',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
