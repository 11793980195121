import { z } from 'zod';

export const CreateAccountRequestSchema = z.object({
    name: z.string(),
    email: z.string(),
    venmo: z.string().optional(),
});
export type CreateAccountRequest = z.infer<typeof CreateAccountRequestSchema>;

export const CreateAccountResponseSchema = z.object({});
export type CreateAccountResponse = z.infer<typeof CreateAccountResponseSchema>;

export const AccountDetailsRequestSchema = z.object({});
export const AccountDetailsResponseSchema = z.object({
    details: z.object({ name: z.string(), email: z.string(), venmo: z.string().optional() }).optional(),
});
export type AccountDetailsRequest = z.infer<typeof AccountDetailsRequestSchema>;
export type AccountDetailsResponse = z.infer<typeof AccountDetailsResponseSchema>;

export const PledgeSchema = z.object({
    projectId: z.string(),
    amountCents: z.number().nonnegative(),
    public: z.boolean(),
});

export const ProjectBackersSchema = z.object({ namedBackers: z.array(z.string()), numAnonymous: z.number() });

export const AddOrUpdatePledgeRequestSchema = z.object({
    pledge: PledgeSchema,
});
export const AddOrUpdatePledgeResponseSchema = z.object({
    pledge: PledgeSchema,
    raiseAmountCents: z.number(),
    backers: ProjectBackersSchema,
});
export type AddOrUpdatePledgeRequest = z.infer<typeof AddOrUpdatePledgeRequestSchema>;
export type AddOrUpdatePledgeResponse = z.infer<typeof AddOrUpdatePledgeResponseSchema>;

export const AppDataRequestSchema = z.object({});
export const AppDataResponseSchema = z.object({
    myPledges: z.array(PledgeSchema),
    raiseAmountsByProjectId: z.record(z.string(), z.number()),
});
export type AppDataRequest = z.infer<typeof AppDataRequestSchema>;
export type AppDataResponse = z.infer<typeof AppDataResponseSchema>;

export const ProjectBackersRequestSchema = z.object({ projectId: z.string() });
export const ProjectBackersResponseSchema = ProjectBackersSchema;
export type ProjectBackersRequest = z.infer<typeof ProjectBackersRequestSchema>;
export type ProjectBackersResposne = z.infer<typeof ProjectBackersResponseSchema>;
