import Launch from '@mui/icons-material/Launch';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Service } from '../API/api';
import { ButtonAppBar } from '../Components/AppBar';
import { PledgeDialog } from '../Components/PledgeDialog';
import { Progress } from '../Components/Progress';
import { pluralize } from '../Components/pluralize';
import { prettyDollars } from '../Components/prettyDollars';
import { useAuth } from '../Config/auth';
import { ProjectBackers, useAppData } from '../Config/pledgeStore';
import { projects } from '../Types/projects';

const MAIN_COLUMN_MAX_WIDTH = 800;

const HeroImage = styled('img')(({ theme }) => ({
    [theme.breakpoints.up(MAIN_COLUMN_MAX_WIDTH)]: {
        marginTop: 24,
    },
    width: '100%',
    aspectRatio: 1.618,
    objectFit: 'cover',
    marginBottom: 24,
}));

interface BackerTextProps {
    backers: ProjectBackers;
}

function BackerText({ backers }: BackerTextProps): React.JSX.Element {
    const allJoinables = [...backers.namedBackers].sort();
    if (backers.numAnonymous) {
        allJoinables.push(pluralize(backers.numAnonymous, 'stealthy giraffe'));
    }
    return (
        <>
            Backed by{' '}
            <DarkGrey>
                {allJoinables.length < 2
                    ? allJoinables.join(', ')
                    : `${allJoinables.slice(0, -1).join(', ')} and ${allJoinables[allJoinables.length - 1]}`}
            </DarkGrey>
        </>
    );
}

const DarkGrey = styled('span')(({ theme }) => ({ color: grey[800] }));

interface ProjectPageProps {
    openFundDialog?: boolean;
}

export function ProjectPage({ openFundDialog }: ProjectPageProps): JSX.Element | null {
    let auth = useAuth();
    let { data, setData } = useAppData();
    const navigate = useNavigate();
    const location = useLocation();

    const { projectId } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [pledgeDialogOpen, setPledgeDialogOpen] = React.useState(!!openFundDialog);
    React.useEffect(() => {
        setPledgeDialogOpen(!!openFundDialog);
    }, [openFundDialog]);

    const backers = projectId ? data.backers[projectId] : undefined;
    const hasBackerData = !!backers && Object.keys(backers).length > 0;
    React.useEffect(() => {
        if (!projectId || hasBackerData) {
            return;
        }
        Service.projectBackers({ projectId }).then(res =>
            setData(state => ({ ...state, backers: { ...state.backers, [projectId]: res } }))
        );
    }, [projectId, hasBackerData, setData]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const project = projects.find(p => p.id === projectId);

    if (!project || !projectId) {
        return null;
    }

    const coleads = project.leads.slice(1);
    const myPledge = data.myPledges[projectId];
    const myPledgeAmount = myPledge?.amountCents ?? 0;
    const raiseAmountDollars = Math.round((data.raiseAmounts[projectId] ?? 0) / 100);
    const hasBackers = !!backers && backers.namedBackers.length + backers.numAnonymous > 0;
    const projectPath = `/projects/${projectId}`;

    return (
        <>
            <ButtonAppBar
                title={project.title}
                onBack={() => {
                    if (location.state && location.state.fromHome) {
                        window.history.back();
                    } else {
                        navigate('/');
                    }
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 36 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: MAIN_COLUMN_MAX_WIDTH,
                    }}
                >
                    <HeroImage src={`${process.env.PUBLIC_URL}/${project.image}`} />
                    <div
                        key={project.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: `0px 16px`,
                        }}
                    >
                        <Typography variant={`h3`} marginBottom={2}>
                            {project.title}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignSelf: 'stretch',
                                alignItems: 'center',
                                gap: 16,
                                marginBottom: 8,
                            }}
                        >
                            <Typography variant={`h5`} color={grey[600]}>
                                {isMobile ? (
                                    <>
                                        Raised <DarkGrey>${raiseAmountDollars}</DarkGrey> /{' '}
                                        <DarkGrey>${project.goal}</DarkGrey>
                                    </>
                                ) : (
                                    <>
                                        Raised <DarkGrey>${raiseAmountDollars}</DarkGrey> of{' '}
                                        <DarkGrey>${project.goal}</DarkGrey> goal
                                    </>
                                )}
                            </Typography>

                            <Button
                                variant={'contained'}
                                color={'success'}
                                style={{ flexShrink: 0 }}
                                onClick={async () => {
                                    if (auth.user) {
                                        navigate('fund');
                                    } else {
                                        navigate(`#login?tofund=${projectId}`);
                                    }
                                }}
                            >
                                {myPledgeAmount > 0 ? `Adjust pledge` : `Fund it! 🔥`}
                            </Button>
                            <PledgeDialog
                                projectName={project.title}
                                initialAmount={myPledgeAmount}
                                open={pledgeDialogOpen}
                                handleClose={() => navigate(projectPath)}
                                handleSubmit={async pledge => {
                                    navigate(projectPath);
                                    const result = await Service.addOrUpdatePledge({
                                        pledge: { ...pledge, projectId },
                                    });
                                    setData(state => ({
                                        ...state,
                                        myPledges: {
                                            ...state.myPledges,
                                            [projectId]: pledge,
                                        },
                                        raiseAmounts: {
                                            ...state.raiseAmounts,
                                            [projectId]: result.raiseAmountCents,
                                        },
                                        backers: {
                                            ...state.backers,
                                            [projectId]: result.backers,
                                        },
                                    }));
                                }}
                            />
                        </div>
                        {myPledgeAmount > 0 && (
                            <Typography variant={`body1`} color={grey[800]} alignSelf={'flex-end'}>
                                You pledged {prettyDollars(myPledgeAmount)} – thank you!
                            </Typography>
                        )}

                        <Progress value={raiseAmountDollars / project.goal} style={{ marginTop: 8 }} />

                        <Typography variant={`body1`} color={grey[600]} marginTop={2}>
                            Lead: <DarkGrey>{project.leads[0]}</DarkGrey>
                            {coleads.length > 0 && (
                                <>
                                     Co-lead{coleads.length > 1 ? 's' : ''}: <DarkGrey>{coleads.join(', ')}</DarkGrey>
                                </>
                            )}
                        </Typography>
                        {project.docUrl && (
                            <Link
                                href={project.docUrl}
                                target={'_blank'}
                                variant={'body2'}
                                fontWeight={500}
                                underline={'none'}
                                marginTop={0}
                            >
                                ONE-PAGER <Launch fontSize={'small'} style={{ position: 'relative', top: 4 }} />
                            </Link>
                        )}
                        <Typography variant={`body1`} sx={{ my: 2 }}>
                            {project.description}
                        </Typography>
                        <Typography variant={`body1`} color={grey[600]}>
                            {hasBackers ? (
                                <BackerText backers={backers} />
                            ) : (
                                'No backers so far – will you be the first?'
                            )}
                        </Typography>

                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}
