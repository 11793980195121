export interface ProjectData {
    id: string;
    title: string;
    image: string;
    docUrl?: string;
    goal: number;
    leads: string[];
    description: React.JSX.Element;
}

export const projects: ProjectData[] = [
    {
        id: 'saloon',
        title: 'Longneck Saloon',
        docUrl: `https://docs.google.com/document/d/1pGxtMnY76L5OPbmoxVtHrWN204BPnZd3mfQjQ7lBwAc`,
        image: 'saloon.png',
        goal: 1250,
        leads: ['Ross Fischer'],
        description: (
            <>
                The Longneck Saloon will be a meticulously crafted, life-sized replica of an authentic frontier saloon,
                complete with a wooden covered porch, vintage decor, and iconic saloon doors beckoning burners to burst
                through and embrace the Wild West spirit (whiskey).
            </>
        ),
    },
    {
        id: 'container-decoration',
        title: 'Union Giraffic Train Car',
        docUrl: `https://docs.google.com/document/d/1oumwBfCIeE1WFJRKXpATtW7_KxTazYewDNLA-XNsD8c`,
        image: 'container-decoration.png',
        goal: 1300,
        leads: ['Evan Litvak', 'Anne Richards'],
        description: (
            <>
                Union Giraffic Train Car is a project that aims to transform the shipping container into a facade of a
                late 1800s passenger train car. This endeavor will balance aesthetics, functionality, and cost, relying
                heavily on painting and wooden attachments to achieve the desired effect. It's worth noting that the
                design is meant to be a facade and viewed directly from the front (this should be considered in layout).
            </>
        ),
    },
    {
        id: 'gym',
        title: 'Gym Enhancements',
        docUrl: `https://docs.google.com/document/d/1SIqNnvl2WFGcOJbxlKHjrENIt5ccTQjn7jME-n5iuaQ`,
        image: 'gym.jpg',
        goal: 4350,
        leads: ['Keefer Taylor', 'Matt Katase', 'Jacob Powers', 'Ross Fischer'],
        description: (
            <>
                In 2022, with generous personal funding from camp members, we purchased the components for a new modular
                scaffolding structure using a style of components called “RingLock”. These components are extremely
                modular which enables us to gradually enhance the underlying structure with new features. For 2023, we
                want to enhance our scaffolding structure by making it easier to climb, utilize more of the volume by
                creating new floors, and building an even taller platform to stand on. For this amount, we will get:
                <ul>
                    <li>A mezzanine on the 2nd floor</li>
                    <li>Two staircases</li>
                    <li>One proper ladder</li>
                </ul>
            </>
        ),
    },
    {
        id: 'kegs',
        title: 'Kegs',
        image: 'kegs.jpg',
        goal: 1600,
        leads: ['Matt Katase'],
        description: <>What is beer? Beer is good! Help us purchase enough of it to keep the taps running all week.</>,
    },
    {
        id: 'fire-breathing-giraffes',
        title: 'Fire-breathing Giraffes',
        image: 'fire_breathing_giraffes.jpeg',
        goal: 445,
        leads: ['George Huo', 'Ed Cheung'],
        description: (
            <>
                Make the giraffes spit fire again! We need to replace some hardware that was lost in the shuffle of the
                pandemic. We may be able to add fiery manes too!
            </>
        ),
    },
    {
        id: 'gym-safety',
        title: 'Gym Safety',
        docUrl: `https://docs.google.com/document/d/14bgCkLaY2J_1kEr-PPGIVq2Pk2c29ekHy-aRcVhKLSs`,
        image: 'gym-safety.jpg',
        goal: 150,
        leads: ['Jacob Powers', 'Keefer Taylor', 'Matt Katase'],
        description: (
            <>
                Last year, much of the work on the gym was not done in a safe manner. We routinely worked unprotected at
                heights where falls would have resulted in critical injuries. This year, the
                <i>Elevated Neck Railworkers</i> have unionized and we are demanding improvements to workplace safety.
                The current plan is to rely on cost effective rock climbing and via-ferrata equipment to provide these
                improvements.
            </>
        ),
    },
    {
        id: 'mister',
        title: 'Chill Space Mister',
        docUrl: `https://docs.google.com/document/d/1EDzbH_BgfE2sQo-vjJPdC_OVDhe64dPyoWOUH17C5pQ`,
        image: 'mister.jpeg',
        goal: 110,
        leads: ['Annie Ling'],
        description: <>TL:DR it’s hot. Let’s buy a mister for the camp chill space.</>,
    },
    {
        id: 'light-tunnel',
        title: 'Light Tunnel',
        docUrl: `https://docs.google.com/document/d/1nKSfkY6p6yb5m2UyfCCoTuIXBH18rDstr8iO9D3qmBo`,
        image: 'light-tunnel.png',
        goal: 800,
        leads: ['Perrin Grace'],
        description: (
            <>
                A cool light tunnel that people can stand in and walk through! It will have the ability to sync the
                lights with music (either automated, or controlled by a human). For an example of what we envision, see{' '}
                <a href={`https://www.youtube.com/live/Zgsfl2z-wLs?feature=share&t=216`}>this YouTube Video.</a>
            </>
        ),
    },
    {
        id: 'side-shade',
        title: 'Side shade upgrade',
        image: 'shade.png',
        goal: 250,
        leads: ['Emily King', 'Zack Smith'],
        description: (
            <>
                We hear your voice, now let’s hear your wallets. Our camp’s existing side shade for shift city wasn’t up
                to snuff. <a href={`https://shadeclothstore.com/product/silvishade-80/`}>This SilviShade 80%</a> comes
                highly recommended — nine 12’x20’ panels and one 12’x10’ panel can cover the three sunny edges of shift
                city. We will need funding by EOD Tue 8/15 to purchase in time for the burn. If we raise at least
                $565.95 I’ll purchase enough to upgrade one side.
            </>
        ),
    },
];
