import { Button, TextField, Typography, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import { red } from '@mui/material/colors';
import { FirebaseError } from 'firebase/app';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { Service } from '../API/api';
import { useAuth } from '../Config/auth';
import { firebaseAuth } from '../Config/firebase';

const FormField = styled(TextField)(({ theme }) => ({
    width: 280,
}));

const CreateAccountSchema = z.object({
    name: z.string().min(1),
    email: z.string().email(),
    venmo: z.union([z.literal(''), z.string().regex(/^@[a-zA-Z][\w-]{4,}$/)]),
    password: z.string().min(5),
    confirmPassword: z.string().min(5),
});

interface CreateAccountData {
    name: string;
    email: string;
    venmo: string;
    password: string;
    confirmPassword: string;
}

export function CreateAccountContent(): React.JSX.Element {
    const auth = useAuth();

    const [formData, setFormData] = React.useState<CreateAccountData>({
        name: '',
        email: '',
        venmo: '',
        password: '',
        confirmPassword: '',
    });

    const [errorEnabled, setErrorEnabled] = React.useState({
        name: false,
        email: false,
        venmo: false,
        password: false,
        confirmPassword: false,
    });
    const [error, setError] = React.useState('');

    const parseResult = CreateAccountSchema.safeParse(formData);
    const passwordsMatch = formData.password === formData.confirmPassword;
    const allGood = parseResult.success && passwordsMatch;
    const errors = {
        name:
            !errorEnabled.name || CreateAccountSchema.shape.name.safeParse(formData.name).success
                ? undefined
                : 'name is required',
        email:
            !errorEnabled.email || CreateAccountSchema.shape.email.safeParse(formData.email).success
                ? undefined
                : formData.email
                ? 'not a valid email address'
                : 'email is required',
        venmo:
            !errorEnabled.venmo || CreateAccountSchema.shape.venmo.safeParse(formData.venmo).success
                ? undefined
                : 'not a valid Venmo username',
        password:
            !errorEnabled.password || CreateAccountSchema.shape.password.safeParse(formData.password).success
                ? undefined
                : formData.password
                ? 'password is not long enough'
                : 'password is required',
        confirmPassword: !errorEnabled.confirmPassword || passwordsMatch ? undefined : 'passwords do not match',
    };

    return (
        <Paper
            elevation={2}
            style={{ padding: 36, display: 'flex', flexFlow: 'column', alignItems: 'flex-start', gap: 16 }}
        >
            <Typography variant={'h5'}>Create an account to get started</Typography>
            <Typography variant={'body1'} marginBottom={2}>
                Already have one? <Link to={'#login'}>Sign in</Link>
            </Typography>

            <FormField
                label={'Name (required)'}
                variant={'standard'}
                onChange={e => {
                    setFormData(data => ({ ...data, name: e.target.value }));
                }}
                error={!!errors.name}
                helperText={errors.name}
                onBlur={() => setErrorEnabled(state => ({ ...state, name: true }))}
            />
            <FormField
                label={'Email address (required)'}
                variant={'standard'}
                autoComplete={'username'}
                onChange={e => {
                    setFormData(data => ({ ...data, email: e.target.value }));
                }}
                error={!!errors.email}
                helperText={errors.email}
                onBlur={() => setErrorEnabled(state => ({ ...state, email: true }))}
            />
            <FormField
                label={'Venmo username'}
                placeholder={'@Your-Username'}
                variant={'standard'}
                onChange={e => {
                    setFormData(data => ({ ...data, venmo: e.target.value }));
                }}
                error={!!errors.venmo}
                helperText={errors.venmo}
                onBlur={() => setErrorEnabled(state => ({ ...state, venmo: true }))}
            />
            <FormField
                label={'Password (required)'}
                type={'password'}
                variant={'standard'}
                autoComplete={'password'}
                onChange={e => {
                    setFormData(data => ({ ...data, password: e.target.value }));
                }}
                error={!!errors.password}
                helperText={errors.password}
                onBlur={() => setErrorEnabled(state => ({ ...state, password: true }))}
            />
            <FormField
                label={'Confirm password'}
                type={'password'}
                variant={'standard'}
                onChange={e => {
                    setFormData(data => ({ ...data, confirmPassword: e.target.value }));
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                onBlur={() => setErrorEnabled(state => ({ ...state, confirmPassword: true }))}
            />

            <Button
                variant={'contained'}
                disabled={!allGood}
                style={{ alignSelf: 'flex-end', marginTop: 16 }}
                onClick={async () => {
                    try {
                        await createUserWithEmailAndPassword(firebaseAuth, formData.email, formData.password);
                        await Service.createAccount({
                            name: formData.name,
                            email: formData.email,
                            venmo: formData.venmo,
                        });
                        auth.setDetails({ name: formData.name, email: formData.email, venmo: formData.venmo });
                    } catch (error) {
                        if (error instanceof FirebaseError) {
                            if (error.code === 'auth/email-already-in-use') {
                                setError('This email address is already in use.');
                                return;
                            }
                        }
                        console.log(error);
                    }
                }}
            >
                Create account
            </Button>
            {error && (
                <Typography variant={'body2'} color={red[500]}>
                    {error}
                </Typography>
            )}
        </Paper>
    );
}
