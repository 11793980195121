import MUICard from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import MUICardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonAppBar } from '../Components/AppBar';
import { Progress } from '../Components/Progress';
import { useAppData } from '../Config/pledgeStore';
import { ProjectData, projects } from '../Types/projects';

const MainGrid = styled(Grid)(({ theme }) => ({
    maxWidth: 1000,
}));

const Card = styled(MUICard)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    margin: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface CardContentProps {
    project: ProjectData;
}

function CardContent({ project }: CardContentProps): JSX.Element {
    const { data } = useAppData();
    const raiseAmountDollars = (data.raiseAmounts[project.id] ?? 0) / 100;

    return (
        <MUICardContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 16 }}>
                <Typography variant={'h5'} textAlign={'start'}>
                    {project.title}
                </Typography>
                <Typography variant={'h6'} textAlign={'end'} alignSelf={'flex-end'}>
                    ${project.goal}
                </Typography>
                <Progress value={raiseAmountDollars / project.goal} />
            </div>
        </MUICardContent>
    );
}

export function HomePage(): JSX.Element | null {
    const navigate = useNavigate();

    return (
        <>
            <ButtonAppBar title={`GoFundGiraffe`} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <MainGrid container spacing={0} justifyContent={'flex-start'}>
                    <Grid item xs={12} marginTop={4} padding={2}>
                        <Typography variant={'h3'} align={'center'}>
                            Welcome to GoFundGiraffe
                        </Typography>
                        <Typography variant={'h6'} align={'center'} color={grey[600]}>
                            Where dreams become things
                        </Typography>
                        <Typography variant={'body1'} marginTop={2}>
                            Browse and pledge support for the projects below. We'll follow up to collect pledged
                            contributions in a few weeks or so.
                        </Typography>
                    </Grid>
                    {projects.map(project => (
                        <Grid item xs={12} md={6} key={project.id} style={{ maxWidth: 500 }}>
                            <Card>
                                <CardActionArea
                                    onClick={() => {
                                        navigate(`/projects/${project.id}`, { state: { fromHome: true } });
                                    }}
                                >
                                    <CardMedia
                                        component={'img'}
                                        style={{ aspectRatio: 1.618 }}
                                        image={`${process.env.PUBLIC_URL}/${project.image}`}
                                    />
                                    <CardContent project={project} />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </MainGrid>
            </div>
        </>
    );
}
