import { green, grey } from '@mui/material/colors';

interface ProgressProps {
    value: number;
    style?: React.CSSProperties;
}

export function Progress({ value, style }: ProgressProps): JSX.Element {
    return (
        <div style={{ width: `100%`, position: 'relative', ...style }}>
            <div
                style={{
                    width: `100%`,
                    height: 16,
                    background: grey[200],
                    borderRadius: 5,
                }}
            />
            <div
                style={{
                    position: `absolute`,
                    top: 0,
                    width: `${Math.min(100, 100 * value)}%`,
                    height: 16,
                    background: green[500],
                    borderRadius: 5,
                }}
            />
        </div>
    );
}
