import axios, { AxiosHeaderValue } from 'axios';
import { z } from 'zod';
import { firebaseAuth } from '../Config/firebase';
import {
    AccountDetailsRequest,
    AccountDetailsResponse,
    AccountDetailsResponseSchema,
    AddOrUpdatePledgeRequest,
    AddOrUpdatePledgeResponse,
    AddOrUpdatePledgeResponseSchema,
    AppDataRequest,
    AppDataResponse,
    AppDataResponseSchema,
    CreateAccountRequest,
    CreateAccountResponse,
    CreateAccountResponseSchema,
    ProjectBackersRequest,
    ProjectBackersResposne as ProjectBackersResponse,
    ProjectBackersResponseSchema,
} from './types';

async function getHeaders(): Promise<{ Authorization?: AxiosHeaderValue }> {
    const user = firebaseAuth.currentUser;
    if (!user) {
        return {};
    }
    const token = await user.getIdToken();
    return { Authorization: token };
}

async function makeApiRequest<T extends z.AnyZodObject>(
    endpoint: string,
    req: any,
    responseSchema: T
): Promise<z.infer<T>> {
    const hostname = window.location.hostname;
    const isLocal = hostname === 'localhost' || hostname === '127.0.0.1';
    const port = isLocal ? ':8080' : '';
    const protocol = isLocal ? 'http' : 'https';
    const response = await axios.post(`${protocol}://${hostname}${port}/api/${endpoint}`, req, {
        headers: await getHeaders(),
    });
    return responseSchema.parse(response.data);
}

export const Service = {
    createAccount(req: CreateAccountRequest): Promise<CreateAccountResponse> {
        return makeApiRequest('createAccount', req, CreateAccountResponseSchema);
    },

    accountDetails(req: AccountDetailsRequest = {}): Promise<AccountDetailsResponse> {
        return makeApiRequest('accountDetails', req, AccountDetailsResponseSchema);
    },

    addOrUpdatePledge(req: AddOrUpdatePledgeRequest): Promise<AddOrUpdatePledgeResponse> {
        return makeApiRequest('addOrUpdatePledge', req, AddOrUpdatePledgeResponseSchema);
    },

    appData(req: AppDataRequest = {}): Promise<AppDataResponse> {
        return makeApiRequest('appData', req, AppDataResponseSchema);
    },

    projectBackers(req: ProjectBackersRequest): Promise<ProjectBackersResponse> {
        return makeApiRequest('projectBackers', req, ProjectBackersResponseSchema);
    },
};
