export function ErrorPage(): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            Not found :(
        </div>
    );
}
