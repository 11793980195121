import { User } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { Service } from '../API/api';
import { firebaseAuth } from './firebase';

interface AccountDetails {
    name: string;
    email: string;
    venmo?: string;
}

interface AuthContext {
    user?: User | null;
    details?: AccountDetails;
    setDetails: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>;
}

function useProvideAuth(): AuthContext {
    const [user, setUser] = useState<User | undefined | null>(undefined);
    const [details, setDetails] = useState<AccountDetails | undefined>(undefined);

    const currentUid = user?.uid;

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(async newUser => {
            if (!newUser) {
                setDetails(undefined);
            } else if (newUser.uid !== currentUid) {
                const accountDetails = await Service.accountDetails();
                setDetails(accountDetails.details);
            }
            setUser(newUser);
        });
        return () => {
            unsubscribe();
        };
    }, [currentUid]);

    return {
        user,
        details,
        setDetails,
    };
}

const authContext = createContext<AuthContext>({ user: undefined, setDetails: () => {} });

export function ProvideAuth({ children }: { children: JSX.Element }) {
    const AuthContextProvider = authContext.Provider;
    const auth = useProvideAuth();
    return <AuthContextProvider value={auth}>{children}</AuthContextProvider>;
}

export function useAuth() {
    return useContext(authContext);
}
