import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import { grey } from '@mui/material/colors';
import React from 'react';
import { prettyDollars } from './prettyDollars';

interface DialogSubmitPledge {
    amountCents: number;
    public: boolean;
}

interface PledgeDialogProps {
    projectName: string;
    initialAmount?: number;
    open: boolean;
    handleClose: () => void;
    handleSubmit: (pledge: DialogSubmitPledge) => void;
}

const PRESET_AMOUNTS = [25, 50, 100];

export function PledgeDialog({
    projectName,
    initialAmount,
    open,
    handleClose,
    handleSubmit,
}: PledgeDialogProps): JSX.Element {
    const [amountCents, setAmountCents] = React.useState<number>(0);
    const [otherAmountText, setOtherAmountText] = React.useState('');
    const [isPublic, setPublic] = React.useState(true);
    const isOtherAmount = !!otherAmountText;

    React.useEffect(() => {
        if (open) {
            setAmountCents(initialAmount ? initialAmount : 2500);
            if (initialAmount && !PRESET_AMOUNTS.includes(initialAmount)) {
                setOtherAmountText(prettyDollars(initialAmount).slice(1));
            }
        }
    }, [initialAmount, open]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
            <DialogContent style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start' }}>
                <DialogContentText variant={'h5'} color={grey[900]} marginBottom={2}>
                    Help fund the {projectName} project!
                </DialogContentText>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">What can we put you down for?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onChange={e => {
                            const value = Number.parseInt(e.currentTarget.value);
                            setAmountCents(value);
                            setOtherAmountText('');
                        }}
                    >
                        {PRESET_AMOUNTS.map(amount => {
                            const myAmountCents = amount * 100;
                            return (
                                <FormControlLabel
                                    key={amount}
                                    value={myAmountCents}
                                    control={<Radio />}
                                    label={`$${amount}`}
                                    checked={!isOtherAmount && amountCents === myAmountCents}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{ marginTop: 1, marginBottom: 3 }} variant={'standard'}>
                    <InputLabel htmlFor="standard-adornment-amount">Other amount</InputLabel>
                    <Input
                        id={'standard-adornment-amount'}
                        startAdornment={<InputAdornment position={'start'}>$</InputAdornment>}
                        type={'number'}
                        value={otherAmountText}
                        onChange={e => {
                            const textValue = e.currentTarget.value;
                            const value = Number.parseFloat(e.currentTarget.value);
                            setAmountCents(isNaN(value) ? 0 : Math.floor(value * 100));
                            if (textValue.length > 1 && textValue.startsWith('0') && !textValue.startsWith('0.')) {
                                // Remove leading zeros
                                setOtherAmountText(textValue.slice(1));
                            } else if (!isNaN(value) && value.toFixed(2).length < e.currentTarget.value.length) {
                                // Remove excess decimal places
                                setOtherAmountText(value.toFixed(2));
                            } else {
                                setOtherAmountText(textValue);
                            }
                        }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Let others see that you chipped in?</FormLabel>
                    <FormControlLabel
                        control={<Switch checked={isPublic} onChange={e => setPublic(e.target.checked)} />}
                        label={
                            <Typography variant={'body2'}>
                                {isPublic ? `We'll show your name, but not the amount.` : `Mum's the word.`}
                            </Typography>
                        }
                    />
                </FormControl>
            </DialogContent>

            <DialogActions sx={{ margin: 2 }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() => handleSubmit({ amountCents, public: isPublic })}
                    variant={'contained'}
                    disabled={amountCents === 0 && !initialAmount}
                >
                    {`Pledge ${prettyDollars(amountCents)}`}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
