import { ThemeProvider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { grey, red } from '@mui/material/colors';
import { createTheme, styled } from '@mui/material/styles';
import { FirebaseError } from 'firebase/app';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { z } from 'zod';
import { firebaseAuth } from '../Config/firebase';

const FormField = styled(TextField)(({ theme }) => ({
    width: 280,
}));

const SignInSchema = z.object({
    email: z.string().email(),
    password: z.string().min(5),
});

type SignInData = z.infer<typeof SignInSchema>;

const theme = createTheme({
    palette: {
        secondary: {
            main: grey[900],
        },
    },
});

interface LoginContentProps {
    redirectPath: string;
}

export function LoginContent({ redirectPath }: LoginContentProps): React.JSX.Element {
    const [formData, setFormData] = React.useState<SignInData>({
        email: '',
        password: '',
    });
    const [error, setError] = React.useState('');

    const parseResult = SignInSchema.safeParse(formData);
    const allGood = parseResult.success;

    const doSignIn = async () => {
        try {
            await signInWithEmailAndPassword(firebaseAuth, formData.email, formData.password);
        } catch (error) {
            if (error instanceof FirebaseError) {
                if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                    setError('Sign in failed: Incorrect email or password.');
                    return;
                }
            }
            console.log(error);
        }
    };
    const keyDownHandler = (e: React.KeyboardEvent<any>) => {
        if (e.key === 'Enter' && allGood) {
            doSignIn();
        }
    };

    return (
        <Paper
            elevation={2}
            style={{ padding: 36, display: 'flex', flexFlow: 'column', alignItems: 'flex-start', gap: 16 }}
        >
            <Typography variant={'h5'} marginBottom={1}>
                Sign in to support a project
            </Typography>

            <ThemeProvider theme={theme}>
                <Button
                    variant={'outlined'}
                    style={{ textTransform: 'none' }}
                    color={'secondary'}
                    onClick={() => {
                        const cookies = new Cookies();
                        cookies.set('ConnectRedirect', redirectPath);
                        window.location.assign('/connect/giraffic');
                    }}
                    startIcon={
                        <Avatar
                            src={`${process.env.PUBLIC_URL}/union_giraffic_bw_small.png`}
                            style={{ width: 24, height: 24 }}
                        />
                    }
                >
                    Sign in with Giraffic.World
                </Button>
            </ThemeProvider>
            <Typography variant={'body1'} marginBottom={0}>
                or enter your email address and password
            </Typography>
            <FormField
                label={'Email address'}
                variant={'standard'}
                autoComplete={'username'}
                onChange={e => {
                    setFormData(data => ({ ...data, email: e.target.value }));
                }}
                onKeyDown={keyDownHandler}
            />
            <FormField
                label={'Password'}
                type={'password'}
                variant={'standard'}
                autoComplete={'current-password'}
                onChange={e => {
                    setFormData(data => ({ ...data, password: e.target.value }));
                }}
                onKeyDown={keyDownHandler}
            />
            <Typography variant={'body1'} marginTop={2}>
                Don't have an account? <Link to={'#create-account'}>Create one</Link>
            </Typography>
            <Button
                variant={'contained'}
                disabled={!allGood}
                style={{ alignSelf: 'flex-end', marginTop: 16 }}
                onClick={doSignIn}
            >
                Sign in
            </Button>
            {error && (
                <Typography variant={'body2'} color={red[500]}>
                    {error}
                </Typography>
            )}
        </Paper>
    );
}
