import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAuth } from '../Config/auth';
import { firebaseAuth } from '../Config/firebase';

const ToolbarOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

function ProfileButton(): React.JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        const cookies = new Cookies();
                        cookies.remove('FirebaseCustomToken', { path: '/', domain: window.location.hostname });
                        firebaseAuth.signOut();
                        handleClose();
                    }}
                >
                    Sign out
                </MenuItem>
            </Menu>
        </div>
    );
}

interface ButtonAppBarProps {
    title: string;
    onBack?: () => void;
}

export function ButtonAppBar({ title, onBack }: ButtonAppBarProps) {
    const auth = useAuth();
    const navigate = useNavigate();

    const userName = auth.details?.name;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    {onBack && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={onBack}
                        >
                            <ArrowBack />
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {auth.user ? (
                        <>
                            {userName && (
                                <Typography variant="body1" component="div">
                                    {userName}
                                </Typography>
                            )}
                            <ProfileButton />
                        </>
                    ) : (
                        <>
                            <Link
                                component={'button'}
                                style={{ color: 'white' }}
                                onClick={() => {
                                    navigate('#login');
                                }}
                            >
                                Sign in
                            </Link>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <ToolbarOffset />
        </Box>
    );
}
