import Dialog from '@mui/material/Dialog';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { signInWithCustomToken } from 'firebase/auth';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ProvideAuth, useAuth } from './Config/auth';
import { firebaseAuth } from './Config/firebase';
import { ProvideAppData } from './Config/pledgeStore';
import { CreateAccountContent } from './Pages/CreateAccount';
import { ErrorPage } from './Pages/Error';
import { HomePage } from './Pages/Home';
import { LoginContent } from './Pages/Login';
import { ProjectPage } from './Pages/Project';

let theme = createTheme();
theme = responsiveFontSizes(theme);

type LoginDialogType = 'login' | 'create-account';

function useParsedHash() {
    const location = useLocation();
    const pathMatch = location.hash.match(/#([^?]*)/);
    const queryMatch = location.hash.match(/\?(.*)/);

    const path = (pathMatch && pathMatch[1]) ?? '';
    const queryString = queryMatch && queryMatch[1];
    const query: Record<string, string> = {};
    if (queryString) {
        const queryComps = queryString.split('&').map(comp => comp.split('='));
        for (const comp of queryComps) {
            const [key, value] = comp;
            if (key && value) {
                query[key] = value;
            }
        }
    }
    return { path, query };
}

function LoginDialog() {
    const { user } = useAuth();
    const { path, query } = useParsedHash();
    const navigate = useNavigate();

    if (user === null) {
        const cookies = new Cookies();
        const firebaseCustomToken = cookies.get('FirebaseCustomToken');
        if (firebaseCustomToken) {
            signInWithCustomToken(firebaseAuth, firebaseCustomToken);
            cookies.remove('FirebaseCustomToken', { path: '/', domain: window.location.hostname });
        }
    }
    const hasUser = !!user;
    const isOpen = path === 'login' || path === 'create-account';
    const toFundProjectId = query['tofund'];
    const redirectPath = toFundProjectId && `/projects/${toFundProjectId}/fund`;
    React.useEffect(() => {
        if (hasUser && isOpen) {
            if (redirectPath) {
                navigate(redirectPath);
            } else {
                navigate('#');
            }
        }
    }, [hasUser, isOpen, redirectPath, navigate]);

    const nextType: LoginDialogType = path === 'login' ? 'login' : 'create-account';
    const [dialogType, setDialogType] = React.useState(nextType);
    React.useEffect(() => {
        // Latch to only change dialog type when it is open.
        if (isOpen) {
            setDialogType(nextType);
        }
    }, [isOpen, nextType]);

    console.log('redirect path is', redirectPath);
    return (
        <Dialog open={isOpen} onClose={() => navigate('#')}>
            {dialogType === 'login' ? <LoginContent redirectPath={redirectPath ?? '/'} /> : <CreateAccountContent />}
        </Dialog>
    );
}

function AppWrapper() {
    return (
        <>
            <Outlet />
            <LoginDialog />
        </>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ProvideAuth>
                <ProvideAppData>
                    <BrowserRouter>
                        <Routes>
                            <Route path={'/'} element={<AppWrapper />}>
                                <Route index element={<HomePage />} errorElement={<ErrorPage />} />
                                <Route path={'/projects/:projectId'} element={<ProjectPage />} />
                                <Route
                                    path={'/projects/:projectId/fund'}
                                    element={<ProjectPage openFundDialog={true} />}
                                />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ProvideAppData>
            </ProvideAuth>
        </ThemeProvider>
    );
}

export default App;
