import { createContext, useContext, useState } from 'react';
import React from 'react';
import { Service } from '../API/api';
import { useAuth } from './auth';

interface MyPledge {
    amountCents: number;
    public: boolean;
}

export interface ProjectBackers {
    namedBackers: string[];
    numAnonymous: number;
}

interface AppData {
    myPledges: Record<string, MyPledge>;
    backers: Record<string, ProjectBackers>;
    raiseAmounts: Record<string, number>;
}

interface AppDataContext {
    data: AppData;
    setData: React.Dispatch<React.SetStateAction<AppData>>;
}

const initialAppData: AppData = { myPledges: {}, backers: {}, raiseAmounts: {} };

function useProvideAppData(): AppDataContext {
    const { user } = useAuth();
    const [data, setData] = useState<AppData>(initialAppData);

    React.useEffect(() => {
        setData(state => ({ ...state, myPledges: {} }));
        Service.appData().then(newData => {
            const myPledges: Record<string, MyPledge> = {};
            for (const myPledge of newData.myPledges) {
                myPledges[myPledge.projectId] = { amountCents: myPledge.amountCents, public: myPledge.public };
            }
            setData(state => ({ ...state, myPledges, raiseAmounts: newData.raiseAmountsByProjectId }));
        });
    }, [user?.uid]);

    return {
        data,
        setData,
    };
}

const dataContext = createContext<AppDataContext>({ data: initialAppData, setData: () => {} });

export function ProvideAppData({ children }: { children: JSX.Element }) {
    const DataContextProvider = dataContext.Provider;
    const value = useProvideAppData();
    return <DataContextProvider value={value}>{children}</DataContextProvider>;
}

export function useAppData() {
    return useContext(dataContext);
}
